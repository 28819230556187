import { IAppSyncCallbacks } from './interfaces/subscription'
import {
  BlazepayGraphql,
  BlazepayConfigModels,
  BlazepayTerminalModels,
  BlazepayPaymentModels,
  BlazepayPaymentOptionModels,
  BlazepayEnums,
} from '@getgreenline/payments'
import { AppSyncAmplify } from '../AppSyncAmplify'
import { CustomerSearch } from '@getgreenline/customer-search'

export const amplify = new AppSyncAmplify()

export const appsyncApi = {
  subscribeFor: (teardown: VoidFunction) => {
    return {
      paymentEvents: (
        merchantId: string,
        terminalId: string,
        callbacks?: IAppSyncCallbacks<BlazepayPaymentModels.IPaymentSubscriptionContract>,
      ) => {
        return amplify.createSubscription<BlazepayPaymentModels.IPaymentSubscriptionContract>({
          query: BlazepayGraphql.Queries.UPDATED_PAYMENT_SUBSCRIPTION_QUERY,
          teardown,
          callbacks,
          variables: {
            merchantId,
            terminalId,
          },
        })
      },
      paymentV2Events: <
        T extends BlazepayEnums.RequestType.PAYMENTS | BlazepayEnums.RequestType.REFUNDS,
      >(
        merchantId: string,
        terminalId: string,
        id?: string,
        callbacks?: IAppSyncCallbacks<BlazepayPaymentModels.IPaymentV2SubscriptionContract<T>>,
      ) => {
        const variables = {
          merchantId,
          terminalId,
        }
        if (id) {
          Object.assign(variables, { id })
        }
        return amplify.createSubscription<BlazepayPaymentModels.IPaymentV2SubscriptionContract<T>>({
          query: BlazepayGraphql.Queries.UPDATED_PAYMENT_V2_SUBSCRIPTION_QUERY,
          teardown,
          callbacks,
          variables,
        })
      },
      terminalEvents: (
        merchantId: string,
        terminalId: string,
        callbacks?: IAppSyncCallbacks<BlazepayTerminalModels.ITerminalSubscriptionResponseContract>,
      ) => {
        return amplify.createSubscription<BlazepayTerminalModels.ITerminalSubscriptionResponseContract>(
          {
            query: BlazepayGraphql.Queries.UPDATED_TERMINAL_SUBSCRIPTION_QUERY,
            teardown,
            callbacks,
            variables: {
              merchantId,
              terminalId,
            },
          },
        )
      },
      paymentOptionEvents: (
        callbacks?: IAppSyncCallbacks<BlazepayPaymentOptionModels.IPaymentOptionSubscriptionResponseContract>,
      ) => {
        return amplify.createSubscription<BlazepayPaymentOptionModels.IPaymentOptionSubscriptionResponseContract>(
          {
            query: BlazepayGraphql.Queries.UPDATED_PAYMENT_OPTION_SUBSCRIPTION_QUERY,
            teardown,
            callbacks,
          },
        )
      },
      configEvents: (
        merchantId: string,
        callbacks?: IAppSyncCallbacks<BlazepayConfigModels.IConfigSubscriptionResponseContract>,
      ) => {
        return amplify.createSubscription<BlazepayConfigModels.IConfigSubscriptionResponseContract>(
          {
            query: BlazepayGraphql.Queries.UPDATED_PAYMENT_CONFIG_SUBSCRIPTION_QUERY,
            teardown,
            callbacks,
            variables: {
              merchantId,
            },
          },
        )
      },
    }
  },
  getCustomersQuery: (
    params: {
      variables: CustomerSearch.API.GetCustomersQueryVariables
    },
    isWebPOS = false,
  ): any => {
    return amplify.performQuery(
      {
        query: CustomerSearch.Queries.getCustomers,
        variables: params.variables,
      },
      isWebPOS,
    )
  },
}
